<script>
  import { Router, Route, Link } from 'svelte-routing';
  import HomePage from './pages/HomePage.svelte';
  import ResumePage from './pages/ResumePage.svelte';
</script>

<div>
  <Router>
    <Route path="/" component={HomePage} />
    <Route path="/resume" component={ResumePage} />
  </Router>
</div>
