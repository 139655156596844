<script lang="ts">
  const resumeLink = '/resume.pdf'; // Caminho relativo ao diretório public
</script>

<style src="./styles/ResumeSection.css"></style>

<section class="resume-section" aria-labelledby="resume-heading">
  <h2 id="resume-heading">My Résumé</h2>
  <p>Download my résumé to learn more about my skills and experiences:</p>

  <div class="logo-container" aria-hidden="true">
    <img src="/logo.svg" alt="Company Logo" class="company-logo" />
  </div>

  <a href={resumeLink} download="resume.pdf" class="download-button" aria-label="Download Résumé"> Download Résumé </a>
</section>
