<script lang="ts">
  import Resume from '../components/Resume.svelte';
  import ResumeSection from '../components/ResumeSection.svelte';
</script>

<style>
</style>

<main>
  <ResumeSection />
  <Resume />
</main>
