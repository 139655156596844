<script lang="ts">
  import MainLayout from '../layouts/MainLayout.svelte';
  import ContactSection from '../components/ContactSection.svelte';
  import AboutUsSection from '../components/AboutUsSection.svelte';
  import ServicesSection from '../components/ServicesSection.svelte';
  import ProfessionalProfileSection from '../components/ProfessionalProfileSection.svelte';
  import ResumePage from '../pages/ResumePage.svelte';
</script>

<style>
  .content {
    margin-top: 84px;
    gap: 16px;
    display: flex;
    flex-direction: column;
  }
</style>

<main>
  <MainLayout>
    <div class="content">
      <AboutUsSection />
      <ProfessionalProfileSection />
      <ServicesSection />
      <ContactSection />
    </div>
  </MainLayout>
</main>
