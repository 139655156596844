<script>
  import { navigate } from 'svelte-routing';
</script>

<style src="./styles/ProfessionalProfileSection.css"></style>

<section class="profile-section">
  <h3 class="profile-title">Professional Profile</h3>
  <div class="row">
    <img src="profile_picture.png" alt="Luiz Henrique Guimaraes" class="profile-image" />
    <p class="profile-description">
      A passionate software developer with a proven track record in delivering innovative solutions. Skilled in both
      frontend and backend technologies, with a focus on creating efficient, user-centric applications.
    </p>
  </div>

  <button class="resume-button" on:click={() => navigate('/resume')}>
    <!-- <button class="resume-button" on:click={() => window.open('/path/to/your/resume.pdf', '_blank')}> -->
    View My Resume
  </button>
</section>
