<script lang="ts">
  const email = 'contact@lhguimaraes.com';
  const subject = 'Job Opportunity Inquiry';
  const body = 'Hello, I would like to learn more about your skills!';
</script>

<style src="./styles/AboutUsSection.css"></style>

<section id="about" class="about-section">
  <h2>About Us</h2>
  <div class="about-content">
    <p>
      At <strong>LHGuimaraes</strong>, we are passionate about creating cutting-edge technology solutions that empower
      businesses to thrive in the digital age. With a commitment to excellence and innovation, our team specializes in
      developing robust systems and applications tailored to meet the unique needs of our clients.
    </p>
    <p>
      Our expertise spans across various domains, including mobile applications, enterprise software, and cloud-based
      systems. We pride ourselves on building solutions that are not only powerful but also scalable, reliable, and
      secure.
    </p>
    <p>
      With years of experience and a dedicated team of professionals, <strong>LHGuimaraes</strong> continues to drive success
      for our clients by transforming complex challenges into seamless and efficient digital solutions.
    </p>
  </div>
  <button
    class="contact-button"
    on:click={() =>
      (window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`)}
  >
    Contact Us
  </button>
</section>
