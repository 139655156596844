<script>
  // Reactive variable for menu open state
  let menuOpen = false;

  // Function to toggle the menu
  function toggleMenu() {
    menuOpen = !menuOpen;
  }

  // Function to close the menu
  function closeMenu() {
    menuOpen = false;
  }

  function scrollToSection(id) {
    const section = document.querySelector(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    closeMenu();
  }
</script>

<style src="./styles/Navbar.css"></style>

<!-- Import your CSS file -->

<nav class="navbar">
  <div class="navbar-logo-wrapper">
    <img src="/logo.svg" alt="Logo" class="navbar-logo" />
    <div class="logo">LHGuimaraes</div>
  </div>

  <ul class="nav-links" class:open={menuOpen}>
    <li>
      <a href="#about" on:click|preventDefault={() => scrollToSection('#about')}>About Us</a>
    </li>
    <li>
      <a href="#services" on:click|preventDefault={() => scrollToSection('#services')}>Services</a>
    </li>
    <li>
      <a href="#contact" on:click|preventDefault={() => scrollToSection('#contact')}>Contact</a>
    </li>
  </ul>

  <!-- Hamburger icon for mobile menu toggle -->
  <div
    class="hamburger"
    role="button"
    tabindex="0"
    on:click={toggleMenu}
    on:keydown={(e) => (e.key === 'Enter' || e.key === ' ') && toggleMenu()}
  >
    <span class="bar"></span>
    <span class="bar"></span>
    <span class="bar"></span>
  </div>
</nav>
