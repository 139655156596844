<script lang="ts">
  import {
    faBriefcase,
    faLaptopCode,
    faChalkboardTeacher,
    faGraduationCap,
    faMapMarkerAlt,
    faPhone,
    faEnvelope,
    faGlobe,
  } from '@fortawesome/free-solid-svg-icons';
  import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
  import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';

  export let name = 'Luiz Guimaraes';
  export let title = 'Software Engineer';
  export let location = 'Charleston, SC';
  export let phone = '843-718-8666';
  export let email = 'luizh.guimaraes@icloud.com';
  export let linkedin = 'https://linkedin.com/in/luizh-guimaraes';
  export let website = 'https://lhguimaraes.com';
</script>

<style src="./styles/Resume.css"></style>

<div class="resume-container">
  <!-- Left Column - Personal Info and Skills -->
  <div class="left-column">
    <div class="header">
      <h1>{name}</h1>
      <p>{title}</p>
    </div>
    <div class="contact-info">
      <p><FontAwesomeIcon icon={faMapMarkerAlt} /> {location}</p>
      <p><FontAwesomeIcon icon={faPhone} /> {phone}</p>
      <p><FontAwesomeIcon icon={faEnvelope} /> <a href={`mailto:${email}`}>{email}</a></p>
      <p>
        <FontAwesomeIcon icon={faLinkedin} /> <a href={linkedin} target="_blank">{linkedin.replace('https://', '')}</a>
      </p>
      <p><FontAwesomeIcon icon={faGlobe} /> <a href={website} target="_blank">lhguimaraes.com</a></p>
      <!-- Novo link de site -->
    </div>

    <div class="section">
      <h2>Professional Summary</h2>
      <p>
        Passionate about professional development, skilled in software engineering, and motivated to improve
        capabilities and succeed in challenging situations.
      </p>
    </div>

    <div class="section">
      <h2>Languages</h2>
      <p>English, Portuguese, Spanish</p>
    </div>

    <div class="section">
      <h2>Areas of Expertise</h2>
      <p>
        Agile Methodologies, Database Management, Object-Oriented Programming, Web and Mobile Development, Problem
        Solving
      </p>
    </div>

    <div class="section">
      <h2>Technical Skills</h2>
      <p>Languages: C/C++, Python, Java, TypeScript</p>
      <p>Web Development: HTML, CSS, React, Node.js</p>
      <p>Mobile Development: Flutter, React Native</p>
      <p>Tools: Git, VS Code, Docker, AWS</p>
    </div>
  </div>

  <!-- Right Column - Professional Experience and Education -->
  <div class="right-column">
    <div class="section">
      <h2>Professional Experience</h2>
      <div class="experience">
        <div class="sub-section">
          <div class="experience-details">
            <p class="experience-title">
              <FontAwesomeIcon icon={faLaptopCode} />Software Developer - Bruza Construction LLC, Daniel Island, USA
            </p>
            <div class="experience-content">
              <span class="date">April 2024 – Present</span>
              <p>
                Developed an enterprise management system using Django for backend, mobile apps for Android and iOS,
                improving operational efficiency.
              </p>
            </div>
          </div>
        </div>
        <div class="sub-section">
          <div class="experience-details">
            <p class="experience-title">
              <FontAwesomeIcon icon={faLaptopCode} />Software Developer - Guilz, Minas Gerais, Brazil
            </p>
            <div class="experience-content">
              <span class="date">Jan 2021 – Dec 2021</span>
              <p>
                Web development for multiple companies, managing databases, system migrations, and improving
                functionalities.
              </p>
            </div>
          </div>
        </div>
        <div class="sub-section">
          <div class="experience-details">
            <p class="experience-title">
              <FontAwesomeIcon icon={faChalkboardTeacher} />Tutor - Pontifical Catholic University, Minas Gerais, Brazil
            </p>
            <div class="experience-content">
              <span class="date">Aug 2020 – Dec 2020</span>
              <p>Assisted professors and students in subjects such as Compilers and Algorithm Project Laboratory.</p>
            </div>
          </div>
        </div>
        <div class="sub-section">
          <div class="experience-details">
            <p class="experience-title">
              <FontAwesomeIcon icon={faLaptopCode} />
              Software Developer - Innovation Intelligence Information and Technology, Minas Gerais, Brazil
            </p>
            <div class="experience-content">
              <span class="date">Mar 2019 – Jul 2020</span>
              <p>
                Led the development of web and mobile applications from concept to deployment, including Android and iOS
                development.
              </p>
            </div>
          </div>
        </div>
        <div class="sub-section">
          <div class="experience-details">
            <p class="experience-title">
              <FontAwesomeIcon icon={faChalkboardTeacher} />Tutor - Pontifical Catholic University, Minas Gerais, Brazil
            </p>
            <div class="experience-content">
              <span class="date">Aug 2018 – Dec 2018</span>
              <p>
                Supported teachers and assisted students by clarifying doubts in subjects such as Algorithms, Data
                Structures, and Discrete Mathematics.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <h2>Education</h2>
      <div class="education">
        <div class="sub-section">
          <div class="experience-details">
            <p class="education-title">
              <FontAwesomeIcon icon={faGraduationCap} />Master of Science in Computer Science
            </p>
            <span class="education-content">Charleston Southern University, Charleston, SC</span>
          </div>
        </div>
        <div class="sub-section">
          <div class="experience-details">
            <p class="education-title">
              <FontAwesomeIcon icon={faGraduationCap} />Bachelor of Science in Computer Science
            </p>
            <span class="education-content">Pontifical Catholic University, Minas Gerais, Brazil</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
