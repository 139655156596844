<script>
  import { createEventDispatcher } from 'svelte';

  console.log(process.env.API_URL + "/addRow");
  let name = '';
  let contact = '';
  let message = '';

  const dispatch = createEventDispatcher();

  // Função para validar email
  const isEmail = (value) => /^\S+@\S+\.\S+$/.test(value);

  // Função para verificar se é um número de telefone dos EUA válido com exatamente 10 dígitos
  const isUSPhone = (value) => /^\(\d{3}\) \d{3}-\d{4}$/.test(value);

  // Função para formatar o número de telefone dos EUA
  const formatPhone = (value) => {
    const cleaned = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    if (cleaned.length > 10) return value; // Evita formatação se houver mais de 10 dígitos
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return value;
  };

  // Função para lidar com alterações no campo de contato
  const handleContactInput = (event) => {
    let value = event.target.value;

    if (!isEmail(value)) {
      value = formatPhone(value);
    }

    contact = value;
  };

  // Função para lidar com o envio do formulário
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isEmail(contact) && !isUSPhone(contact)) {
      alert('Please enter a valid email or a US phone number in the format (XXX) XXX-XXXX.');
      return;
    }

    try {
      const response = await fetch(`${process.env.API_URL}/addRow`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, contact, message }),
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      alert('Message sent successfully!');
      dispatch('submit', { name, contact, message });

      // Reset form fields
      name = '';
      contact = '';
      message = '';
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error sending the message. Please try again later.');
    }
  };
</script>

<style src="./styles/ContactSection.css"></style>

<section id="contact" class="contact-section">
  <h2>Contact</h2>
  <div class="contact-form">
    <form on:submit={handleSubmit} class="contact-form">
      <label for="name">Name:</label>
      <input type="text" id="name" bind:value={name} required />

      <label for="contact">Email or Phone:</label>
      <input type="text" id="contact" bind:value={contact} on:input={handleContactInput} required />

      <label for="message">Message:</label>
      <textarea id="message" bind:value={message} required></textarea>

      <button type="submit" class="btn-cta">Send Message</button>
    </form>
  </div>
</section>
