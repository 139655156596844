<script>
  import { faCode, faMobileAlt, faProjectDiagram, faCloud } from '@fortawesome/free-solid-svg-icons';
  import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';
</script>

<style src="./styles/ServicesSection.css"></style>

<section id="services" class="services-section">
  <h2>Our Services</h2>
  <div class="services-list">
    <div class="service-item">
      <h3>Custom Software Development</h3>
      <span class="service-icon">
        <FontAwesomeIcon icon={faCode} class="service-icon" />
      </span>
      <p>
        We create tailor-made software solutions to address specific business challenges. From concept to deployment,
        each project is built with a focus on reliability and scalability to meet business goals.
      </p>
    </div>
    <div class="service-item">
      <h3>Mobile Application Development</h3>
      <span class="service-icon">
        <FontAwesomeIcon icon={faMobileAlt} class="service-icon" />
      </span>
      <p>
        Specializing in mobile app development for iOS and Android, we bring ideas to life with modern, efficient
        applications that offer high-quality user experiences across devices.
      </p>
    </div>
    <div class="service-item">
      <h3>Enterprise System Integration</h3>
      <span class="service-icon">
        <FontAwesomeIcon icon={faProjectDiagram} class="service-icon" />
      </span>
      <p>
        We integrate enterprise systems to ensure seamless communication and functionality across platforms. From CRM to
        ERP solutions, we enhance operational efficiency with secure and effective integrations.
      </p>
    </div>
    <div class="service-item">
      <h3>Cloud Solutions & Scalability</h3>
      <span class="service-icon">
        <FontAwesomeIcon icon={faCloud} class="service-icon" />
      </span>
      <p>
        With expertise in cloud architecture, we help businesses transition to scalable cloud solutions, ensuring
        systems are secure and prepared for growth.
      </p>
    </div>
  </div>
</section>
